import React, { Component, Fragment } from 'react';
import { Card, Row, Col, message, Modal, Form, Input, Checkbox, Button, Icon } from 'antd';

import axios from 'axios';

import {
  getProfilesRequest,
  createProfilesRequest,
  updateProfilesRequest,
  createViewMyDataRequest,
  getProviderTokenReq,
  getDocumentRequest,
  createUpdateProviderTokenReq,
  getGarminRequestTokenReq,
  getGarminAccessTokenReq
} from '../../services/api';

import { messageService } from '../../services/messageService';

import './Profile.scss';

const FITBIT_CLIENT_ID = process.env.REACT_APP_FITBIT_CLIENT_ID;
const FITBIT_AUTH_CODE = process.env.REACT_APP_FITBIT_AUTH_CODE;
const FITBIT_CODE_VERIFIER = process.env.REACT_APP_FITBIT_CODE_VERIFIER;
const FITBIT_CODE_CHALLENGE = process.env.REACT_APP_FITBIT_CODE_CHALLENGE;

const WITHINGS_CLIENT_ID = process.env.REACT_APP_WITHINGS_CLIENT_ID;
const WITHINGS_CLIENT_SECRET = process.env.REACT_APP_WITHINGS_CLIENT_SECRET;

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
const REDIRECT_URL_FITBIT = `${REDIRECT_URL}/fitbit`;
const REDIRECT_URL_GARMIN = `${REDIRECT_URL}/garmin`;
const REDIRECT_URL_WITHINGS = `${REDIRECT_URL}/withings`;

class Profile extends Component {
  state = {
    showProfileEdit: false,
    loading: false,
    profile: {},
    documentWC: {},
    documentEULA: {},
    documentTOS: {},
    profileCompleted: 20,
    profileCreated: false,
    fitbitConnected: false,
    garminConnected: false,
    withingsConnected: false,
    mode: 'ADD',
    progressStyle: { width: '30%' },
    fitbitConfig: {
      url: 'www.fitbit.com',
      apiUrl: 'api.fitbit.com',
      clientId: FITBIT_CLIENT_ID,
      redirectUrl: REDIRECT_URL_FITBIT, // https://selfb.org/user/profile/fitbit
      grantType: 'authorization_code',
      authCode: FITBIT_AUTH_CODE, // auth code needs to be generated from https://dev.fitbit.com/apps/details/22BQ8S
      responseType: 'code',
      codeVerifier: FITBIT_CODE_VERIFIER,
      codeChallange: FITBIT_CODE_CHALLENGE,
      codeChallangeMethod: 'S256',
      scope: 'activity heartrate location nutrition profile settings sleep social weight cardio_fitness electrocardiogram oxygen_saturation respiratory_rate temperature',
      expiresIn: 31536000 // 86400 for 1 day, 604800 for 1 week, 2592000 for 30 days, 31536000 for 1 year
    },
    api: {
      authorize: 'https://www.fitbit.com/oauth2/authorize?',
      token: 'https://api.fitbit.com/oauth2/token?',
      user: 'https://api.fitbit.com/1.2/user/-/profile.json'
    }
  };

  componentWillMount() {
    this.getProfile();
    this.getDocument();
    this.getFitbitDetailsFromDB();
    this.getGarminDetailsFromDB();
    this.getWithingsDetailsFromDB();
  }

  componentDidMount = () => {
    const params = window.location.search;
    const url = window.location.href;

    const queryParams = new URLSearchParams(params);
    if (params) {
      const fitbitUrl = url.includes('fitbit');
      const garminUrl = url.includes('garmin');
      const withingsUrl = url.includes('withings');

      if (fitbitUrl) {
        const oauthCode = queryParams.get('code');
        this.getFitBitAccessToken(oauthCode);
      }

      if (garminUrl) {
        const oauthToken = queryParams.get('oauth_token');
        const oauthVerifier = queryParams.get('oauth_verifier');
        this.getGarminAccessToken(oauthToken, oauthVerifier);
      }

      if (withingsUrl) {
        const oauthCode = queryParams.get('code');
        this.getWithingsAccessToken(oauthCode);
      }
    }
  };

  handleFitbitSubmit = e => {
    e.preventDefault();
    this.onConnectToFitBit();
  };

  onConnectToFitBit = () => {
    const {
      clientId,
      responseType,
      codeChallange,
      codeChallangeMethod,
      scope,
      redirectUrl,
      expiresIn
    } = this.state.fitbitConfig;
    const { api } = this.state;
    window.location.href = `${api.authorize}client_id=${clientId}&response_type=${responseType}&code_challenge=${codeChallange}&code_challenge_method=${codeChallangeMethod}&scope=${scope}&redirect_uri=${redirectUrl}&expires_in=${expiresIn}`;
  };

  getFitBitAccessToken = code => {
    const { clientId, grantType, redirectUrl, authCode, codeVerifier } = this.state.fitbitConfig;
    const { api } = this.state;
    axios
      .post(
        `${api.token}client_id=${clientId}&grant_type=${grantType}&redirect_uri=${redirectUrl}&code=${code}&code_verifier=${codeVerifier}`,
        '',
        {
          headers: {
            Authorization: `Basic ${authCode}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .then(response => {
        localStorage.setItem('fitbit_user', JSON.stringify(response.data));
        this.getFitBitUserDetails(response.data);
        this.createUpdateFitbitTokenToDB(response.data);

        let { profileCompleted } = this.state;
        profileCompleted += 20;
        this.setState({
          fitbitConnected: true,
          profileCompleted,
          progressStyle: { width: `${profileCompleted}%` }
        });
      })
      .catch(error => {
        // Fitbit connection error messaage.
        message.error('Unfortunately there was an error connecting Fitbit. Please try again.');
        // console.log(error);
      });
  };

  getFitBitUserDetails = token => {
    const { api } = this.state;
    axios
      .get(`${api.user}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`
        }
      })
      .then(response => {
        // console.log(response.data);
      })
      .catch(error => {
        // console.log(error);
      });
  };

  createUpdateFitbitTokenToDB = token => {
    const { fitbitConfig } = this.state;
    const fitbitUserLS = JSON.parse(localStorage.getItem('fitbit_user')) || {};

    const data = {
      vendor: 'fitbit',
      access_token: fitbitUserLS.access_token,
      refresh_token: fitbitUserLS.refresh_token,
      redirect_url: fitbitConfig.redirectUrl
    };
    localStorage.removeItem('fitbit_config');
    try {
      createUpdateProviderTokenReq(data, 'CREATE')
        .then(res => {
          message.info('Connected to Fitbit successfully!.');
          setTimeout(() => {
            window.location.href = '/user/profile';
          }, 1000);
        })
        .catch(error => {});
    } catch (error) {
      message.error('Unfortunately there was an error updating fibit data');
    }
  };

  createUpdateWithingsTokenToDB = withingsData => {

    const data = {
      vendor: 'withings',
      access_token: withingsData.access_token,
      refresh_token: withingsData.refresh_token,
      redirect_url: REDIRECT_URL_WITHINGS
    };

    try {
      createUpdateProviderTokenReq(data, 'CREATE')
        .then(res => {
          message.info('Connected to Wi-things successfully!.');
          setTimeout(() => {
            window.location.href = '/user/profile';
          }, 1000);
        })
        .catch(error => {});
    } catch (error) {
      message.error('Unfortunately there was an error connecting to Wi-things');
    }
  };

  getFitbitDetailsFromDB = async () => {
    try {
      getProviderTokenReq()
        .then(res => {
          if (res.data && res.data.length) {
            const fitbitData = res.data.filter(el => el.vendor === 'fitbit');
            if (fitbitData && fitbitData.length) {
              let { profileCompleted } = this.state;
              profileCompleted += 20;
              this.setState({
                fitbitConnected: true,
                profileCompleted,
                progressStyle: { width: `${profileCompleted}%` }
              });
            }
          }
        })
        .catch(error => {});
    } catch (error) {
      message.error('Unfortunately there was an error getting the Fitbit token.');
    }
  };

  getProfile = async () => {
    try {
      getProfilesRequest()
        .then(res => {
          this.setState(
            {
              profile: res.data && res.data.length ? res.data[0] : {},
              mode: res.data && res.data.length ? 'EDIT' : 'ADD'
            },
            () => {
              if (this.state.mode === 'ADD') {
                this.setState({ showProfileEdit: false, profileCreated: false });
              } else {
                let { profileCompleted } = this.state;
                profileCompleted = profileCompleted + 20;
                this.setState({
                  profileCreated: true,
                  profileCompleted,
                  progressStyle: { width: `${profileCompleted}%` }
                });
              }
            }
          );
          // console.log(res.data[0]);
          if (res.data && res.data.length) {
            messageService.sendMessage(res.data[0]);
          }
        })
        .catch(error => {});
    } catch (error) {
      message.error('Unfortunately there was an error getting the profile');
    }
  };


  getDocument = async () => {
    try {
      getDocumentRequest()
        .then(res => {
        res.data.forEach(doc => {
            if(doc.type === "wc"){
              this.setState( { documentWC: doc } );
            }
            if(doc.type === "eula"){
              this.setState( { documentEULA: doc } );
            }
            if(doc.type === "tos"){
              this.setState( { documentTOS: doc } );
            }
          });

        })
        .catch(error => {});
    } catch (error) {
      message.error('Unfortunately there was an error getting the documents');
    }
  };


  handleSubmit = e => {
    const { mode } = this.state;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (
        !values.firstname ||
        !values.lastname ||
        !values.address ||
        !values.city ||
        !values.country ||
        !values.eula ||
        !values.terms_of_services ||
        !values.written_consent
      ) {
        return;
      }
      values.no_mail = !values.no_mail; 
      values['eula_document'] = this.state.documentEULA.uuid;
      values['written_consent_document'] = this.state.documentWC.uuid;
      values['terms_of_services_document'] = this.state.documentTOS.uuid;
      this.setState({ loading: true });
      const profile = values;
      if (mode === 'ADD') {
        createProfilesRequest(profile)
          .then(response => {
            messageService.sendMessage(response.data);
            message.error('Profile created sucessfully.');
            this.setState({
              profile: response.data,
              loading: false,
              showProfileEdit: false,
              mode: 'EDIT'
            });
          })
          .catch(error => {
            this.handleHTTPError(error);
          });
      } else {
        profile.uuid = this.state.profile.uuid;
        updateProfilesRequest(profile)
          .then(response => {
            messageService.sendMessage(response.data);
            message.error('Profile updated sucessfully.');
            this.setState({ profile: response.data, loading: false, showProfileEdit: false });
          })
          .catch(error => {
            this.handleHTTPError(error);
          });
      }
    });
  };

  handleHTTPError = error => {
    this.setState({ loading: false });
    if (error && error.response && error.response.data && Object.keys(error.response.data).length) {
      const errMsg = error.response.data[Object.keys(error.response.data)[0]][0];
      if (errMsg) {
        if (typeof errMsg === 'object') {
          message.error(JSON.stringify(errMsg));
        } else {
          message.error(errMsg);
        }
      } else {
        message.error(JSON.stringify(errMsg));
      }
    } else {
      message.error('Unfortunately there was an error.');
    }
  };

  handleCancelModal = () => {
    if (this.state.mode === 'ADD') {
      message.error('Please create the profile to continue.');
      return;
    }
    this.setState({ showProfileEdit: false });
  };

  showProfileEditModel = () => {
    if(this.state.profile.eula_document !== this.state.documentEULA.uuid){
      this.state.profile.eula = false;
    }
    if(this.state.profile.written_consent_document !== this.state.documentWC.uuid){
      this.state.profile.written_consent = false;
    }

    if(this.state.profile.terms_of_services_document !== this.state.documentTOS.uuid){
      this.state.profile.terms_of_services = false;
    }
    this.setState({ showProfileEdit: true });
  };

  handleViewMyData = () => {
    const data = {
      topic: 'view my data',
      message: 'view my data',
      done: false
    };
    createViewMyDataRequest(data)
      .then(response => {
        message.error('Message has been sent.');
      })
      .catch(error => {
        this.handleHTTPError(error);
      });
  };

  copyTextToClipboard = text => {
    const copyText = document.querySelector('#copy-text');
    copyText.value = text;
    copyText.select();
    document.execCommand('copy');
  };

  createUpdateGarminTokensToDB = (oauthToken, oauthTokenSecret) => {
    const data = {
      vendor: 'garmin',
      oauth_token: oauthToken,
      oauth_token_secret: oauthTokenSecret,
      redirect_url: REDIRECT_URL
    };

    try {
      createUpdateProviderTokenReq(data, 'CREATE')
        .then(res => {
          message.info('Connected to Garmin successfully!');
          setTimeout(() => {
            window.location.href = '/user/profile';
          }, 1000);
        })
        .catch(error => {});
    } catch (error) {
      message.error('Unfortunately there was an error updating garmin data');
    }
  };

  getGarminDetailsFromDB = async () => {
    try {
      getProviderTokenReq()
        .then(res => {
          if (res.data && res.data.length) {
            const garminData = res.data.filter(el => el.vendor === 'garmin');
            if (garminData && garminData.length) {
              let { profileCompleted } = this.state;
              profileCompleted += 20;
              this.setState({
                garminConnected: true,
                profileCompleted,
                progressStyle: { width: `${profileCompleted}%` }
              });
            }
          }
        })
        .catch(error => {
          message.error('Unfortunately there was an error getting the Garmin token.');
        });
    } catch (error) {
      message.error('Unfortunately there was an error getting the Garmin token.');
    }
  };

  getWithingsDetailsFromDB = async () => {
    try {
      getProviderTokenReq()
        .then(res => {
          if (res.data && res.data.length) {
            const withingsData = res.data.filter(el => el.vendor === 'withings');
            if (withingsData && withingsData.length) {
              let { profileCompleted } = this.state;
              profileCompleted += 20;
              this.setState({
                withingsConnected: true,
                profileCompleted,
                progressStyle: { width: `${profileCompleted}%` }
              });
            }
          }
        })
        .catch(error => {
          message.error('Unfortunately there was an error getting the Wi-things token.');
        });
    } catch (error) {
      message.error('Unfortunately there was an error getting the Wi-things token.');
    }
  };

  // Garmin Oauth Connect (Step 1 & 2)
  onConnectToGarmin = async () => {
    try {
      getGarminRequestTokenReq()
        .then(res => {
          const data = res.data;
          const oauthToken = data.oauth_token;
          const oauthTokenSecret = data.oauth_token_secret;
          const userConsentUrl = 'https://connect.garmin.com/oauthConfirm?';
          const callbackUrl = REDIRECT_URL_GARMIN;

          if (oauthToken && oauthTokenSecret) {
            localStorage.setItem('garmin_oauth_token_secret', oauthTokenSecret);
            window.location.href = `${userConsentUrl}oauth_token=${oauthToken}&oauth_callback=${callbackUrl}`;
          }
        })
        .catch(error => {
          message.error('Unfortunately there was an error getting the Garmin token.');
        });
    } catch (error) {
      message.error('Unfortunately there was an error getting the Garmin token.');
    }
  };

  // Garmin Oauth Connect (Step 3)
  getGarminAccessToken = (oauthToken, oauthVerifier) => {
    const oauthTokenSecret = localStorage.getItem('garmin_oauth_token_secret');
    try {
      getGarminAccessTokenReq(oauthToken, oauthTokenSecret, oauthVerifier)
        .then(res => {
          const data = res.data;
          const newOauthToken = data.oauth_token;
          const newOauthTokenSecret = data.oauth_token_secret;

          if (oauthToken && oauthTokenSecret) {
            this.createUpdateGarminTokensToDB(newOauthToken, newOauthTokenSecret);
            let { profileCompleted } = this.state;
            profileCompleted += 20;
            this.setState({
              garminConnected: true,
              profileCompleted,
              progressStyle: { width: `${profileCompleted}%` }
            });
            localStorage.removeItem('garmin_oauth_token_secret');
          }
        })
        .catch(error => {
          message.error('Unfortunately there was an error connecting to Garmin.');
        });
    } catch (error) {
      message.error('Unfortunately there was an error connecting to Garmin.');
    }
  };

  //Withings oauth connect
  onConnectToWithings = async () => {
    const response_type = 'code';
    const client_id = WITHINGS_CLIENT_ID;
    const state = '1';
    const scope = 'user.activity,user.metrics';
    const redirect_uri = REDIRECT_URL_WITHINGS;
    window.location.href = `https://account.withings.com/oauth2_user/authorize2?response_type=${response_type}&client_id=${client_id}&state=${state}&scope=${scope}&redirect_uri=${redirect_uri}`;
  };

  //Withings get access token
  getWithingsAccessToken = oauthcode => {
    const apiRequest = 'https://wbsapi.withings.net/v2/oauth2';
    const client_id = WITHINGS_CLIENT_ID;
    const client_secret = WITHINGS_CLIENT_SECRET;
    const action = 'requesttoken';
    const grant_type = 'authorization_code';
    const code = oauthcode;
    const redirect_uri = REDIRECT_URL_WITHINGS;

    const reqBody = {
      action: action,
      client_id: client_id,
      client_secret: client_secret,
      grant_type: grant_type,
      code: code,
      redirect_uri: redirect_uri
    };

    axios
      .post(apiRequest, reqBody, {
        headers: { 'Content-Type': 'application/json'}
      })
      .then(response => {
        // console.log('Response', response.data.body);

        this.createUpdateWithingsTokenToDB(response.data.body);

        let { profileCompleted } = this.state;
        profileCompleted += 20;
        this.setState({
          withingsConnected: true,
          profileCompleted,
          progressStyle: { width: `${profileCompleted}%` }
        });
      })
      .catch(error => {
        // Withings connection error message.
        message.error('Unfortunately there was an error connecting Wi-things. Please try again.');
        // console.log(error);
      });
  };

  render() {
    const {
      showProfileEdit,
      profile,
      documentEULA,
      documentWC,
      documentTOS,
      loading,
      mode,
      profileCompleted,
      progressStyle,
      profileCreated,
      fitbitConnected,
      garminConnected,
      withingsConnected
      // fitbitUserConfig
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <Fragment>
        <input id="copy-text" type="text" />
        <div className="container profile-wrapper">
          <div className="btn-wrapper">
            <Button type="primary" className="round-button" onClick={this.showProfileEditModel}>
              <Icon type="edit" />
              Edit Profile
            </Button>
            <Button type="primary" className="round-button" onClick={this.handleViewMyData}>
              <Icon type="mail" />
              View my Data
            </Button>
            <Button type="primary" className="round-button">
              <Icon type="mail" />
              Retreive my Data
            </Button>
          </div>
        </div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={7}>
            <div className="profile-complete-wrapper">
              <Card className="profile-card" title="Complete your Profile">
                <h4 className="title">
                  Profile:
                  <span className="completed"> {profileCompleted}%</span>
                </h4>
                <div className="profile-complete-outer">
                  <div className="profile-complete-inner" style={progressStyle} />
                </div>
                <h4 className="prof-title">
                  Account
                  <Icon type="check-circle" />
                  <span className="count">20%</span>
                </h4>

                <h4 className="prof-title">
                  Profile
                  {profileCreated && <Icon type="check-circle" />}
                  <span className={profileCreated ? 'count' : ''}>20%</span>
                </h4>
                <div>
                  <Button
                    type="primary"
                    className="round-button"
                    onClick={this.showProfileEditModel}
                  >
                    {profileCreated ? 'Edit' : 'Create'} Profile
                  </Button>
                </div>

                <h4 className="prof-title">
                  Connect with Fitbit
                  {fitbitConnected && <Icon type="check-circle" />}
                  <span className={fitbitConnected ? 'count' : ''}>20%</span>
                </h4>
                <div>
                  <Button
                    type="primary"
                    className="round-button"
                    disabled={fitbitConnected}
                    onClick={this.onConnectToFitBit}
                  >
                    Connect
                  </Button>
                </div>

                <h4 className="prof-title">
                  Connect with Garmin
                  {garminConnected && <Icon type="check-circle" />}
                  <span className={garminConnected ? 'count' : ''}>20%</span>
                </h4>
                <div>
                  <Button
                    type="primary"
                    className="round-button"
                    disabled={garminConnected}
                    onClick={this.onConnectToGarmin}
                  >
                    Connect
                  </Button>
                </div>

                <h4 className="prof-title">
                  Connect with Withings
                  {withingsConnected && <Icon type="check-circle" />}
                  <span className={withingsConnected ? 'count' : ''}>20%</span>
                </h4>
                <div>
                  <Button
                    type="primary"
                    className="round-button"
                    disabled={withingsConnected}
                    onClick={this.onConnectToWithings}
                  >
                    Connect
                  </Button>
                </div>
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={24} md={17}>
            <div className="container profile-wrapper">
              <Card className="profile-card" title="Profile">
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={12}>
                    Firstname
                  </Col>
                  <Col xs={24} sm={24} md={12} className="form-value">
                    {profile.firstname}
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={12}>
                    Lastname
                  </Col>
                  <Col xs={24} sm={24} md={12} className="form-value">
                    {profile.lastname}
                  </Col>
                </Row>
                {/* <Row gutter={20}>
              <Col  xs={24} sm={24} md={12}>Gender</Col>
              <Col  xs={24} sm={24} md={12}>{profile.gender}</Col>
            </Row>
            <Row gutter={20}>
              <Col  xs={24} sm={24} md={12}>Birthdate (YYYY-MM-DD)</Col>
              <Col  xs={24} sm={24} md={12}>{profile.birthdate}</Col>
            </Row>
            <Row gutter={20}>
              <Col  xs={24} sm={24} md={12}>Weight (Grams)</Col>
              <Col  xs={24} sm={24} md={12}>{profile.weight_grams}</Col>
            </Row>
            <Row gutter={20}>
              <Col  xs={24} sm={24} md={12}>Length (cm)</Col>
              <Col  xs={24} sm={24} md={12}>{profile.length_cm}</Col>
            </Row>
            <Row gutter={20}>
              <Col  xs={24} sm={24} md={12}>Cardio</Col>
              <Col  xs={24} sm={24} md={12}>{profile.cardio}</Col>
            </Row>
            <Row gutter={20}>
              <Col  xs={24} sm={24} md={12}>Domicile</Col>
              <Col  xs={24} sm={24} md={12}>{profile.dom}</Col>
            </Row> */}
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={12}>
                    Address
                  </Col>
                  <Col xs={24} sm={24} md={12} className="form-value">
                    {profile.address}
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={12}>
                    City
                  </Col>
                  <Col xs={24} sm={24} md={12} className="form-value">
                    {profile.city}
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={12}>
                    Country
                  </Col>
                  <Col xs={24} sm={24} md={12} className="form-value">
                    {profile.country}
                  </Col>
                </Row>
                {/* <Row gutter={20}>
              <Col  xs={24} sm={24} md={12}>Zipcode</Col>
              <Col  xs={24} sm={24} md={12}>{profile.zipcode}</Col>
            </Row> */}

                <Row gutter={20}>
                  <Col span={24}>
                    <Checkbox disabled checked={(profile.eula_document === documentEULA.uuid ? true : false)}>
                      I agree with EULA.
                    </Checkbox>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={24}>
                    <Checkbox disabled checked={(profile.written_consent_document === documentWC.uuid ? true : false)}>
                      I agree with written consent.
                    </Checkbox>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={24}>
                    <Checkbox disabled checked={(profile.terms_of_services_document=== documentTOS.uuid ? true : false)}>
                      I agree with Terms and Services.
                    </Checkbox>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>

        <div className="profile-edit-form">
          <Modal
            visible={showProfileEdit}
            title={`${mode === 'ADD' ? 'Create' : 'Edit'} Profile`}
            onCancel={this.handleCancelModal}
            maskClosable={false}
            keyboard={false}
            width={750}
            footer={[
              <Button key="back" className="round-button" onClick={this.handleCancelModal}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                className="round-button"
                loading={loading}
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            ]}
            className="admin-modal"
          >
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Firstname" hasFeedback>
                    {getFieldDecorator('firstname', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.firstname,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Firstname!',
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Firstname" maxLength={120} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Lastname" hasFeedback>
                    {getFieldDecorator('lastname', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.lastname,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the lastname!',
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Lastname" maxLength={120} />)}
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row gutter={12}>
                <Col  xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Gender">
                    {getFieldDecorator('gender', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.gender,
                      rules: [
                        {
                          required: true,
                          message: 'Please select the Gender!',
                          whitespace: true
                        }
                      ]
                    })(
                      <Radio.Group>
                        <Radio value="male">Male</Radio>
                        <Radio value="female">Female</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col  xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Birthdate (YYYY-MM-DD)" hasFeedback>
                    {getFieldDecorator('birthdate', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.birthdate,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Birthdate!',
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Birthdate (YYYY-MM-DD)" maxLength={120} />)}
                  </Form.Item>
                </Col>
              </Row> */}

              {/* <Row gutter={12}>
                <Col  xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Weight (Grams)" hasFeedback>
                    {getFieldDecorator('weight_grams', {
                      initialValue: profile.weight_grams,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Weight (Grams)!'
                        }
                      ]
                    })(<InputNumber placeholder="Weight (Grams)" />)}
                  </Form.Item>
                </Col>
                <Col  xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Length (cm)" hasFeedback>
                    {getFieldDecorator('length_cm', {
                      initialValue: profile.length_cm,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Length (cm)!'
                        }
                      ]
                    })(<InputNumber placeholder="Length (cm)" />)}
                  </Form.Item>
                </Col>
              </Row> */}

              {/* <Row gutter={12}>
                <Col  xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Cardio" hasFeedback>
                    {getFieldDecorator('cardio', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.cardio,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Cardio!',
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Cardio" maxLength={120} />)}
                  </Form.Item>
                </Col>
                <Col  xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Domicile" hasFeedback>
                    {getFieldDecorator('dom', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.dom,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Domicile!',
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Domicile" maxLength={120} />)}
                  </Form.Item>
                </Col>
              </Row> */}

              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Address" hasFeedback>
                    {getFieldDecorator('address', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.address,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Address!',
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Address" maxLength={120} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="City" hasFeedback>
                    {getFieldDecorator('city', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.city,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the City!',
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="City" maxLength={120} />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Country" hasFeedback>
                    {getFieldDecorator('country', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.country,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Country!',
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Country" maxLength={120} />)}
                  </Form.Item>
                </Col>
                {/* <Col  xs={24} sm={24} md={12}>
                  <Form.Item colon={false} label="Zipcode" hasFeedback>
                    {getFieldDecorator('zipcode', {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: profile.zipcode,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Zipcode!',
                          whitespace: true
                        }
                      ]
                    })(<Input placeholder="Zipcode" maxLength={120} />)}
                  </Form.Item>
                </Col> */}
              </Row>

              <Form.Item colon={false} label="">
                {getFieldDecorator('eula', {
                  valuePropName: 'checked',
                  initialValue: profile.eula,
                  rules: [
                    {
                      required: true,
                      message: 'Please check the EULA!'
                    }
                  ]
                })(<Checkbox>I agree with <a href={documentEULA.link} target="_blank" rel="noopener noreferrer">EULA</a>.</Checkbox>)}
              </Form.Item>

              <Form.Item colon={false} label="">
                {getFieldDecorator('written_consent', {
                  valuePropName: 'checked',
                  initialValue: profile.written_consent,
                  rules: [
                    {
                      required: true,
                      message: 'Please check the written consent!'
                    }
                  ]
                })(<Checkbox>I agree with <a href={documentWC.link} target="_blank" rel="noopener noreferrer">written consent</a>.</Checkbox>)}
              </Form.Item>

              <Form.Item colon={false} label="">
                {getFieldDecorator('terms_of_services', {
                  valuePropName: 'checked',
                  initialValue: profile.terms_of_services,
                  rules: [
                    {
                      required: true,
                      message: 'Please check the Terms and Services!'
                    }
                  ]
                })(<Checkbox>I agree with <a href={documentTOS.link} target="_blank" rel="noopener noreferrer">Terms and Services</a>.</Checkbox>)}
              </Form.Item>

              <Form.Item colon={false} label="">
                {getFieldDecorator('no_mail', {
                  valuePropName: 'checked',
                  initialValue: !profile.no_mail,
                  rules: [
                    {
                      required: false,
                    }
                  ]
                })(<Checkbox>Subscribe to Mails.</Checkbox>)}
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

const WrappedProfile = Form.create({ name: 'profile' })(Profile);

export default WrappedProfile;
