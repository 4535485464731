/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Icon, Input, Button, message } from 'antd';

import './LoginForm.scss';
import { loginRequest } from '../../services/api';

const FormItem = Form.Item;

class LoginForm extends React.Component {
  state = {
    isLoggingIn: false,
    isShowPassword: false
  };

  usernameInput = React.createRef();

  passwordInput = React.createRef();

  componentDidMount = () => {
    localStorage.clear();
  };

  handleSubmit = e => {
    const { form } = this.props;

    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.login(values);
        this.setState({
          isLoggingIn: true
        });
      }
    });
  };

  login = values => {
    loginRequest(values)
      .then(response => {
        this.handleLoginSuccess(response);
      })
      .catch(error => {
        this.handleLoginError(error.response);
      });
  };

  handleLoginSuccess = response => {
    const { history } = this.props;
    history.push('/user/profile');
    localStorage.setItem('auth', JSON.stringify(response.data));
  };

  handleLoginError = error => {
    this.setState({
      isLoggingIn: false
    });
    if (error && error.data && Object.keys(error.data).length) {
      const errMsg = error.data[Object.keys(error.data)[0]][0];
      if (errMsg) {
        if (typeof errMsg === 'object') {
          message.error(JSON.stringify(errMsg));
        } else {
          message.error(errMsg);
        }
      } else {
        message.error(JSON.stringify(errMsg));
      }
      this.usernameInput.current.focus();
    } else {
      message.error('Unfortunately there was an error, please try again.');
    }

    // if (error && error.data && error.data.error) {
    //   message.error(error.data.error);
    //   this.usernameInput.current.focus();
    // } else {
    //   message.error('Unfortunately there was an error, please try again');
    //   form.resetFields();
    // }
  };

  showHidePassword = () => {
    const { isShowPassword } = this.state;
    this.setState({ isShowPassword: !isShowPassword });
    this.passwordInput.current.focus();
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { isLoggingIn, isShowPassword } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <FormItem>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please enter your username!' }]
          })(
            <Input
              ref={this.usernameInput}
              // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              autoFocus
              autoComplete="off"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please enter your password!' }]
          })(
            <Input
              ref={this.passwordInput}
              suffix={
                <Icon
                  type={isShowPassword ? 'eye' : 'eye-invisible'}
                  title={isShowPassword ? 'Hide Password' : 'Show Password'}
                  className="sh-password"
                  onClick={this.showHidePassword}
                />
              }
              type={isShowPassword ? 'text' : 'password'}
              placeholder="Password"
              autoComplete="off"
            />
          )}
        </FormItem>
        <FormItem>
          {isLoggingIn && (
            <Button type="primary" htmlType="submit" className="login-form-button" disabled>
              <Icon type="sync" className="login-spinner-icon" spin />
            </Button>
          )}
          {!isLoggingIn && (
            <Button type="primary" htmlType="submit" className="login-form-button">
              LOGIN
            </Button>
          )}
        </FormItem>

        {/* <Button type="primary" className="login-form-button" onClick={this.onConnectToFitBit}>
          Connect to Fitbit
        </Button> */}

        <div className="signup-msg">
          <Link to="/home/signup">Forgot Password</Link>
        </div>
        <div className="signup-msg">
          <p>Don't have account?</p>
          <Link to="/home/signup">SIGN UP NOW</Link>
        </div>
      </Form>
    );
  }
}

LoginForm.propTypes = {
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(Form.create()(LoginForm));
