import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter, Redirect, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import classNames from 'classnames';

import GlobalHeader from '../../components/Header/Header';
import ProfilePage from '../../pages/ProfilePage/ProfilePage';

import './PrimaryLayout.scss';

const { Content } = Layout;

class App extends Component {
  state = {};

  componentWillMount() {}

  render() {
    const { location } = this.props;
    const globalContentClasses = classNames('global-content', {
      'global-profile': location.pathname === '/profile',
      'global-content-devices': (location.pathname === '/edit-profile') === 0
    });

    return (
      <Layout className="global-layout">
        <GlobalHeader />
        <Content className={globalContentClasses}>
          <Switch>
            {<Route path="/user/profile" component={ProfilePage} />}
            {<Redirect to="/user/profile" />}
          </Switch>
        </Content>
      </Layout>
    );
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(App);
