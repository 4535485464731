import React, { Component } from 'react';
import { messageService } from '../../services/messageService';

class PorfileNav extends Component {
  state = {
    user: {}
  };

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe(data => {
      if (data.message) {
        this.setState({ user: data.message });
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    const { user } = this.state;
    return (
      <>
        <div className="profile-bg">
          {user.firstname && (
            <div className="profile-initial">{user.firstname.substring(0, 1)}</div>
          )}
        </div>
        {user.firstname && <h4 className="profile-name">{`${user.firstname} ${user.lastname}`}</h4>}
      </>
    );
  }
}

PorfileNav.propTypes = {};

export default PorfileNav;
