import React, { Fragment } from 'react';
import Profile from '../../components/Profile/Profile';

function ProfilePage() {
  return (
    <Fragment>
      <Profile />
    </Fragment>
  );
}

export default ProfilePage;
