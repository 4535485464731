import React from 'react';
import { Layout } from 'antd';

import Nav from './Nav';
import PorfileNav from './PorfileNav';

import './Header.scss';

const { Header } = Layout;

function GlobalHeader() {
  return (
    <>
      <Header className="global-header">
        <div className="container clearfix">
          <a className="brand" href="/">
            <div className="logo" />
            <span className="name" />
          </a>
          <Nav />
        </div>
      </Header>
      <PorfileNav />
    </>
  );
}

export default GlobalHeader;
