import axios from 'axios';
import moment from 'moment';

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

/* eslint-disable no-param-reassign */
axios.interceptors.request.use(
  config => {
    if (config.url.indexOf('fitbit.com') >= 0) {
      return config;
    }
    let auth = localStorage.getItem('auth');
    const loginUrl = `${API_DOMAIN}/auth/rest-auth/login/`;
    const signupUrl = `${API_DOMAIN}/auth/rest-auth/registration/`;

    let token = null;
    let loginExpiryMoment = null;
    let loginExpired = null;

    if (config.url === loginUrl || config.url === signupUrl || auth === 'undefined') {
      localStorage.removeItem('auth');
      auth = null;
    }
    if (auth) {
      const parsedAuth = JSON.parse(auth);

      token = parsedAuth.key;
      loginExpiryMoment = moment.unix(parsedAuth.logged_in_till);
      loginExpired = moment().isAfter(loginExpiryMoment);

      if (loginExpired) {
        localStorage.removeItem('auth');
        window.location.href = '/home/login';
        return;
      }

      if (token) {
        // eslint-disable-next-line dot-notation
        config.headers['Authorization'] = `Token ${token}`;
      }
    } else if (config.url !== loginUrl && config.url !== signupUrl) {
      window.location.href = '/home/login';
    }

    // eslint-disable-next-line consistent-return
    return config;
  },

  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      // window.location.href = '/home/login';
    }
    return Promise.reject(error);
  }
);

export const loginRequest = values =>
  axios({
    method: 'post',
    url: `${API_DOMAIN}/auth/rest-auth/login/`,
    timeout: 10 * 1000,
    data: values
  });

export const userResgistraionReq = query =>
  axios.post(`${API_DOMAIN}/auth/rest-auth/registration/`, query);

export const getProfilesRequest = () =>
  axios.get(`${API_DOMAIN}/api/profile/`, {
    params: {
      origin: 'frontend'
    }
  });

  export const getDocumentRequest = () =>
  axios.get(`${API_DOMAIN}/api/document/`, {
    params: {
      origin: 'frontend'
    }
  });

export const createProfilesRequest = query => axios.post(`${API_DOMAIN}/api/profile/`, query);

export const updateProfilesRequest = query =>
  axios.put(`${API_DOMAIN}/api/profile/${query.uuid}/`, query);

export const createViewMyDataRequest = query => axios.post(`${API_DOMAIN}/api/message/`, query);

export const getProviderTokenReq = () =>
  axios.get(`${API_DOMAIN}/api/dataprovider/`, {
    params: {
      origin: 'frontend'
    }
  });

export const createUpdateProviderTokenReq = (query, method) => {
  if (method === 'CREATE') {
    return axios.post(`${API_DOMAIN}/api/dataprovider/`, query);
  }
  return axios.put(`${API_DOMAIN}/api/dataprovider/`, query);
};

export const getGarminRequestTokenReq = () =>
  axios.post(`${API_DOMAIN}/api/dataprovider/garmin_request_token/`, {
    params: {
      origin: 'frontend'
    }
  });

export const getGarminAccessTokenReq = (oauthToken, oauthTokenSecret, oauthVerifier) =>
  axios.post(`${API_DOMAIN}/api/dataprovider/garmin_access_token/`, {
    oauthToken,
    oauthTokenSecret,
    oauthVerifier
  });
